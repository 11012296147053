@font-face {
    font-family: "NotoSansKr";
    font-style: normal;
    font-weight: 300;
    src: url(../public/fonts/kr/NotoSansKR-Light.otf) format('opentype')
}

@font-face {
    font-family: "NotoSansKr";
    font-style: normal;
    font-weight: 400;
    src: url(../public/fonts/kr/NotoSansKR-Regular.otf) format('opentype')
}

@font-face {
    font-family: "NotoSansKr";
    font-style: normal;
    font-weight: 500;
    src: url(../public/fonts/kr/NotoSansKR-Medium.otf) format('opentype')
}

@font-face {
    font-family: "NotoSansKr";
    font-style: normal;
    font-weight: 600;
    src: url(../public/fonts/kr/NotoSansKR-Bold.otf) format('opentype')
}
@font-face {
    font-family: "NotoSansJP";
    font-style: normal;
    font-weight: 300;
    src: url(../public/fonts/jp/NotoSansJP-Light.otf) format('opentype')
}

@font-face {
    font-family: "NotoSansJP";
    font-style: normal;
    font-weight: 400;
    src: url(../public/fonts/jp/NotoSansJP-Regular.otf) format('opentype')
}

@font-face {
    font-family: "NotoSansJP";
    font-style: normal;
    font-weight: 500;
    src: url(../public/fonts/jp/NotoSansJP-Medium.otf) format('opentype')
}

@font-face {
    font-family: "NotoSansJP";
    font-style: normal;
    font-weight: 600;
    src: url(../public/fonts/jp/NotoSansJP-Bold.otf) format('opentype')
}
@font-face {
    font-family: "NotoSansCn";
    font-style: normal;
    font-weight: 300;
    src: url(../public/fonts/cn/NotoSansSC-Light.otf) format('opentype')
}

@font-face {
    font-family: "NotoSansCn";
    font-style: normal;
    font-weight: 400;
    src: url(../public/fonts/cn/NotoSansSC-Regular.otf) format('opentype')
}

@font-face {
    font-family: "NotoSansCn";
    font-style: normal;
    font-weight: 500;
    src: url(../public/fonts/cn/NotoSansSC-Medium.otf) format('opentype')
}

@font-face {
    font-family: "NotoSansCn";
    font-style: normal;
    font-weight: 600;
    src: url(../public/fonts/cn/NotoSansSC-Bold.otf) format('opentype')
}
@font-face {
    font-family: "SEBANGGothic";
    font-style: normal;
    font-weight: 500;
    src: url(../public/fonts/kr/SEBANGGothic.otf) format('opentype')
}
@font-face {
    font-family: "SEBANGGothic";
    font-style: normal;
    font-weight: 600;
    src: url(../public/fonts/kr/SEBANGGothicBold.otf) format('opentype')
}
@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    src: url(../public/fonts/kr/Pretendard-Regular.woff) format('woff')
}
@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    src: url(../public/fonts/kr/Pretendard-Medium.woff) format('woff')
}
@font-face {
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    src: url(../public/fonts/kr/Pretendard-Bold.woff) format('woff')
}
